import { Navbar } from "@/components/navbar";
import { useAppSelector } from "@/redux/hooks";

import { SalesChart } from "../Home/components/sales-chart";
import { TotalRevenueCard } from "../Home/components/total-revenue-card";
import ChargerGroupContributionsCard from "./components/charger-group-contributions-card";
import SettlementCycle from "./components/settlment-cycle";
import TransactionsTable from "./components/transaction-table/transactions-table";
import Intercom from '@intercom/messenger-js-sdk';

function Revenue() {
  const { totalRevenue, loading } = useAppSelector((state) => state.home);
  const { user } = useAppSelector((state) => state.auth);
  Intercom({
    app_id: 'coujd56h',
    user_id: user?.id,
    name: user ? `${user.first_name} ${user.last_name}` : undefined,
    email: user?.email,
    // Note: createdAt is not available in the UserType, so we'll omit it
  });

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
        className="mb-10 flex flex-col gap-5 bg-cover px-4 pt-4 md:px-9 md:pt-9 2xl:gap-11"
      >
        <div className="container">
          <Navbar title="Revenue Insight" />
        </div>
        <div className="container relative mx-auto flex flex-col gap-9">
          <div className="flex flex-col gap-5 xl:flex-row">
            <div className="flex flex-col space-y-4">
              {totalRevenue && (
                <div className="flex h-max flex-col gap-5 xl:flex-row">
                  <TotalRevenueCard data={totalRevenue} loading={loading} />
                </div>
              )}
              <div className="flex h-full flex-col gap-5 xl:flex-row">
                <SettlementCycle />
              </div>
            </div>
            <div className="flex-1">
              <ChargerGroupContributionsCard />
            </div>
          </div>
          <div>
            <SalesChart />
          </div>
          <div>
            <TransactionsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Revenue;
