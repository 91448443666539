import { Navbar } from "@/components/navbar";

import ChargerStatus from "./components/charger-status";
import { ChargePointSetupInstructions } from "./components/charger-table/charge-point-setup-instruction";
import { ChargerTabs } from "./components/charger-tabs";
import { ChargerUtilization } from "./components/charger-utilization";
import { useAppSelector } from "@/redux/hooks";
import Intercom from '@intercom/messenger-js-sdk';

type Properties = {};

export default function ChargerNetwork({}: Properties) {
  const { user } = useAppSelector((state) => state.auth);
  Intercom({
    app_id: 'coujd56h',
    user_id: user?.id,
    name: user ? `${user.first_name} ${user.last_name}` : undefined,
    email: user?.email,
    // Note: createdAt is not available in the UserType, so we'll omit it
  });
  return (
    <div className="space-y-6">
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container -mb-10 flex flex-col gap-6 bg-cover pt-4 md:pt-9">
          <Navbar title="Charger Network" />
          <div className="flex flex-col gap-5 xl:flex-row">
            <div className="my-2 flex w-full gap-4">
              <ChargerStatus />
              {/* <ChargerMaintenance /> */} <ChargerUtilization />
            </div>
          </div>
        </div>
      </div>
      <div className="container !mt-16">
        <ChargerTabs />
      </div>
      <ChargePointSetupInstructions />
    </div>
  );
}
