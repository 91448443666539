import { Navbar } from "@/components/navbar";
import { AccessControlMetrics } from "./components/access-control-metrics/access-control-metrics";
import { CardHoldersAndAccessProfileTab } from "./components/card-holders-and-access-profile-tab/card-holders-and-access-profile-tab";
import { useAppSelector } from "@/redux/hooks";
import Intercom from '@intercom/messenger-js-sdk';


export const AccessControl = () => {
  const { user } = useAppSelector((state) => state.auth);
  Intercom({
    app_id: 'coujd56h',
    user_id: user?.id,
    name: user ? `${user.first_name} ${user.last_name}` : undefined,
    email: user?.email,
    // Note: createdAt is not available in the UserType, so we'll omit it
  });
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container -mb-10 flex flex-col gap-5 bg-cover px-4 pt-4 md:px-9 md:pt-9 2xl:gap-11">
          <Navbar title="Access Control" />
          <div className="">
            <AccessControlMetrics />
          </div>
        </div>
      </div>
      <div className="container relative mt-16">
        <CardHoldersAndAccessProfileTab />
      </div>
    </div>
  );
};
